// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mujeres-js": () => import("./../../../src/pages/mujeres.js" /* webpackChunkName: "component---src-pages-mujeres-js" */),
  "component---src-pages-organizaciones-js": () => import("./../../../src/pages/organizaciones.js" /* webpackChunkName: "component---src-pages-organizaciones-js" */),
  "component---src-pages-planes-de-incidencia-js": () => import("./../../../src/pages/planes-de-incidencia.js" /* webpackChunkName: "component---src-pages-planes-de-incidencia-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-templates-mujer-js": () => import("./../../../src/templates/mujer.js" /* webpackChunkName: "component---src-templates-mujer-js" */)
}

